import { useNotify, useRedirect } from 'react-admin';

import CraftCreate from 'src/components/CraftCreate';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import FeatureFlagsTab from './formTabs/FeatureFlags';
import InternalSettingsTab from './formTabs/InternalSettingsTab';
import SubscriptionTab from './formTabs/Subscription';
import { createOrganizationSchema } from './validation';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import { createOrganization, handleFormFiles, updateOrganization } from './helpers';

export const PortalOrgsCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const defaultValues = {
    settings: {
      data: {
        featureFlags: {
          riskCategoryField: true,
          riskCategoryChart: true,
          companyRequests: true,
          advancedSearch: true,
          compareCompanies: true,
        },
      },
    },
  };

  const handleSave = async (rawValues: Record<string, unknown>) => {
    const validationResult = createOrganizationSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    const orgParams = {
      name: values.name,
      adminEmailAddress: values.admin_email_address,
      logoUrl: values.logo?.src,
      productName: values.product_name,
      type: values.type,
      craftDashboardsAccess: values.craft_dashboards_access,
      craftDashboardsHosts: values.craft_dashboards_hosts_transformed,
      riskDashboardAccess: values.risk_dashboard_access,
      ssoEnabled: values.sso_enabled,
      britishSteel: values.british_steel,
      alerts: values.alerts,

      subscription: {
        startDate: values.subscription.startDate,
        endDate: values.subscription.endDate,
      },

      settings: {
        data: {
          intercomId: values.settings.data.intercomId,

          craftForTeamsSettings: {
            enabled: values.settings.data.craftForTeamsSettings.enabled,
            trialStartDate: values.settings.data.craftForTeamsSettings.trialStartDate,
            trialEndDate: values.settings.data.craftForTeamsSettings.trialEndDate,
          },

          limits: {
            enabled: values.settings.data.limits.enabled,
            portfolioLimit: values.settings.data.limits.portfolioLimit,
            portfolioItemsLimit: values.settings.data.limits.portfolioItemsLimit,
            userAccountLimit: values.settings.data.limits.userAccountLimit,
          },

          customView: {
            enabled: values.settings.data.customView.enabled,
            homePageOverride: values.settings.data.customView.homePageOverride,
            homepageTitle: values.settings.data.customView.homepageTitle,
            homepageMessage: values.settings.data.customView.homepageMessage,
            homepageProductTitle: values.settings.data.customView.homepageProductTitle,
            homepageProductTitleColor: values.settings.data.customView.homepageProductTitleColor,
            hideHomeSidebarLink: values.settings.data.customView.hideHomeSidebarLink,
            hideHelpCenterLink: values.settings.data.customView.hideHelpCenterLink,
            showPoweredByCraft: values.settings.data.customView.showPoweredByCraft,
            hideDefaultSearchSuggestions: values.settings.data.customView.hideDefaultSearchSuggestions,
            smallLogoUrl: values.settings.data.customView.smallLogoUrl,
            customSidebarLinks: values.settings.data.customView.customSidebarLinks,
          },

          featureFlags: {
            insights: values.settings.data.featureFlags.insights,
            nTier: values.settings.data.featureFlags.nTier,
            nTierNew: values.settings.data.featureFlags.nTierNew,
            nTierCentralityScore: values.settings.data.featureFlags.nTierCentralityScore,
            nTierImpact: values.settings.data.featureFlags.nTierImpact,
            sensitive: values.settings.data.featureFlags.sensitive,
            firstPartyData: values.settings.data.featureFlags.firstPartyData,
            gsca: values.settings.data.featureFlags.gsca,
            caseManagementEnabled: values.settings.data.featureFlags.caseManagementEnabled,
            caseManagementLocations: values.settings.data.featureFlags.caseManagementLocations,
            internalMailDomains: values.settings.data.featureFlags.internalMailDomains,
            riskCategoryField: values.settings.data.featureFlags.riskCategoryField,
            riskCategoryChart: values.settings.data.featureFlags.riskCategoryChart,
            hideDefaultRiskConfig: values.settings.data.featureFlags.hideDefaultRiskConfig,
            companyRequests: values.settings.data.featureFlags.companyRequests,
            advancedSearch: values.settings.data.featureFlags.advancedSearch,
            compareCompanies: values.settings.data.featureFlags.compareCompanies,
            totpMFAEnabled: values.settings.data.featureFlags.totpMFAEnabled,
            customJobBusinessUnitEnabled: values.settings.data.featureFlags.customJobBusinessUnitEnabled,
            maxPortfolioDepth: values.settings.data.featureFlags.maxPortfolioDepth,
            portfolioCheckEnabled: values.settings.data.featureFlags.portfolioCheckEnabled,
            brandedEmails: values.settings.data.featureFlags.brandedEmails,
            defaultCommentAccess: values.settings.data.featureFlags.defaultCommentAccess,
          },

          suggestions: values.settings.data.suggestions,

          samlSettings: values.settings.data.samlSettings && {
            certs: values.settings.data.samlSettings.certs_transformed,
            issuer: values.settings.data.samlSettings.issuer,
            spCerts: values.settings.data.samlSettings.spCerts,
            entryPoint: values.settings.data.samlSettings.entryPoint,
            accountName: values.settings.data.samlSettings.accountName,
          },

          certaSettings: {
            enabled: values.settings.data.certaSettings.enabled,
            tenantName: values.settings.data.certaSettings.tenantName,
          },

          internalOrganizationSettings: {
            showAdminPanel: values.settings.data.internalOrganizationSettings.showAdminPanel,
          },

          securityScoreCardReportDownload: {
            enabled: values.settings.data.securityScoreCardReportDownload.enabled,
            companySummaryReportEnabled:
              values.settings.data.securityScoreCardReportDownload.companySummaryReportEnabled,
            companyIssueReportEnabled: values.settings.data.securityScoreCardReportDownload.companyIssueReportEnabled,
          },

          notifications: values.settings.data.notifications_transformed,
        },
      },
    };

    const { data, errors } = await createOrganization(orgParams);

    if (errors) {
      notify(
        'Oops. There was a problem creating the organization. Please refresh and try again, or contact IT Support.',
        { type: 'error', autoHideDuration: 10000 },
      );
      console.error('Error response on organization create:', errors);
      return undefined;
    }

    const orgID = data?.createOrganization.id;
    if (!orgID) {
      notify('Unable to redirect to organization: Organization ID not returned', { type: 'error' });
      return redirect('list', 'organizations');
    }

    // Notify user that org has been created. Any errors that follow can be addressed via edit view.
    notify('The organization has been successfully created', { type: 'success' });

    const orgEditURL = `/organizations/${orgID}`;

    // handle files
    try {
      const { validationErrors, orgLogoURL, pdfLogoOverrideURL } = await handleFormFiles(orgID, values);
      if (validationErrors) return validationErrors;

      if (orgLogoURL || pdfLogoOverrideURL) {
        const { errors: updateErrors } = await updateOrganization(orgID, {
          logoUrl: orgLogoURL,
          // settings data can't be partially updated
          settings: { data: { ...orgParams.settings.data, pdfLogoOverrideURL } },
        });
        if (updateErrors) {
          console.error(updateErrors);
          notify(`Error saving files: ${updateErrors[0].message}`, { type: 'error' });
        }
      }
    } catch (e) {
      console.error(e);
      const errorMsg = e instanceof Error ? e.message : '';
      notify(`Error uploading files${errorMsg && `: ${errorMsg}`}`, { type: 'error' });
    }

    return redirect(orgEditURL);
  };

  return (
    <CraftCreate header="Create a new Organization">
      <CraftTabbedForm formType="create" defaultValues={defaultValues} onSubmit={handleSave} shouldUnregister>
        <DetailsTab mode="create" />
        <FeatureFlagsTab />
        <InternalSettingsTab />
        <SubscriptionTab mode="create" />
      </CraftTabbedForm>
    </CraftCreate>
  );
};

export default PortalOrgsCreate;
