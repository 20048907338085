import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

import { config } from 'src/config/config.local';

const httpLink = createHttpLink({
  uri: config.clientAPI.url,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

export default client;
