import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import {
  Loading,
  TabbedFormProps,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { Box } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';

import client from 'src/data/api';
import CraftEdit from 'src/components/CraftEdit';
import CraftTabbedForm from 'src/components/CraftTabbedForm';
import DetailsTab from './formTabs/Details';
import CompaniesTab from './formTabs/Companies';
import DataPackagesTab from './formTabs/DataPackages';
import EditAside from './EditAside';
import LoadingOverlay from 'src/components/LoadingOverlay';
import {
  CompanySubscriptionSetRecord,
  CompanySubscriptionSetSubscriptionCounts,
  DefaultDataPackageRecord,
} from 'src/types';
import { usePermissions } from 'src/hooks';
import { zodIssuesIntoErrorsMap } from 'src/utils/validation';
import {
  AddCompanySubscriptionsDialog,
  AddCompanySubscriptionsDialogContextProvider,
} from './formTabs/sections/AddCompanySubscriptionsDialog';
import { ExternalApiClientEditTab, externalApiClientEditRoute } from 'src/utils/routeHelpers';
import { companySubscriptionSetSchema, resolveMutationParams, updateCompanySubscriptionSetGQL } from './helpers';
import { CompanySubscriptionSetContextProvider, useCompanySubscriptionSetContext } from './Context';

interface CustomTabbedFormProps extends TabbedFormProps {
  defaultDataPackageIdSet?: Set<number> | undefined;
  defaultDataPackageIdSetLoading: boolean;
}

const CustomTabbedForm = ({
  children,
  defaultDataPackageIdSet,
  defaultDataPackageIdSetLoading,
  ...rest
}: CustomTabbedFormProps) => {
  const record = useRecordContext<CompanySubscriptionSetRecord>();
  const companySubscriptionSetCtx = useCompanySubscriptionSetContext();
  const { permissions } = usePermissions();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [defaultValues, setDefaultValues] = useState<Record<string, unknown>>();

  const isAllCompaniesSet = record?.all_companies;
  const canEditCompanySubscriptionSet = permissions.has('companySubscriptionSet:edit');

  const {
    data: currentSubscriptionCountsData,
    error: currentSubscriptionCountsError,
    loading: currentSubscriptionCountsLoading,
    refetch: currentSubscriptionCountsRefetch,
  } = useQuery<{
    companySubscriptionSetSubscriptionCounts: CompanySubscriptionSetSubscriptionCounts | null;
  }>(
    gql`
      query companySubscriptionSetSubscriptionCounts($externalApiClientID: ID!, $companySubscriptionSetID: ID!) {
        companySubscriptionSetSubscriptionCounts(
          externalApiClientID: $externalApiClientID
          companySubscriptionSetID: $companySubscriptionSetID
        ) {
          total
          resolved
          reserved
        }
      }
    `,
    {
      skip: !record || isAllCompaniesSet,
      client,
      variables: {
        externalApiClientID: record?.external_api_client_id,
        companySubscriptionSetID: record?.id,
      },
      errorPolicy: 'all',
    },
  );

  if (currentSubscriptionCountsError) {
    console.error(currentSubscriptionCountsError);
    notify('Error fetching company subscription data', { type: 'error' });
  }

  const currentSubscriptionCounts = currentSubscriptionCountsData?.companySubscriptionSetSubscriptionCounts;

  if (
    record &&
    !isAllCompaniesSet &&
    !currentSubscriptionCountsLoading &&
    (currentSubscriptionCounts === undefined || currentSubscriptionCounts === null)
  ) {
    console.error('Unexpected response for current subscription counts', currentSubscriptionCountsData);
    notify('There was a problem fetching company subscription data', { type: 'error' });
  }

  useEffect(() => {
    if (currentSubscriptionCounts == null) return;
    companySubscriptionSetCtx.setCurrentSubscriptionCounts(currentSubscriptionCounts);
  }, [currentSubscriptionCounts]);

  useEffect(() => {
    companySubscriptionSetCtx.setCurrentSubscriptionCountsRefetch(currentSubscriptionCountsRefetch);
  }, [currentSubscriptionCountsRefetch]);

  const [softDeleteCompanySubscriptionSet, { isLoading: softDeleteCompanySubscriptionSetLoading }] = useUpdate();

  const [updateCompanySubscriptionSet, { loading: updateCompanySubscriptionSetLoading }] = useMutation(
    updateCompanySubscriptionSetGQL,
    {
      client,
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (!record || defaultValues) return;

    const initialDefaultVals: { dataPackages: Record<string, boolean> } = { dataPackages: {} };

    for (const { data_package_id } of record.data_packages_xrefs) {
      // data package entry keys are IDs prefixed with "id:"
      initialDefaultVals.dataPackages[`id:${data_package_id}`] = true;
    }

    setDefaultValues(initialDefaultVals);
  }, [record]);

  if (record?.deleted_at) {
    redirect('list', 'external_api_clients');
    return null;
  }

  const handleSave = async (rawValues: Record<string, unknown>) => {
    if (!record) return undefined;

    const validationResult = companySubscriptionSetSchema.safeParse(rawValues);
    if (!validationResult.success) return zodIssuesIntoErrorsMap(validationResult.error.issues);
    const values = validationResult.data;

    if (!defaultDataPackageIdSet) {
      notify('There was a problem loading Data Package data. Please try again.', {
        type: 'error',
        autoHideDuration: 8000,
      });
      return undefined;
    }

    if (!isAllCompaniesSet) {
      if (currentSubscriptionCounts === undefined || currentSubscriptionCounts === null) {
        notify('There was a problem validating the company limit. Please try again.', {
          type: 'error',
          autoHideDuration: 8000,
        });
        return undefined;
      }

      if ((validationResult.data.company_limit ?? 0) < currentSubscriptionCounts.total) {
        notify(
          `Company limit must be greater than or equal to the number of company subscriptions
  currently in the set, which is ${currentSubscriptionCounts.total}.`,
          { type: 'error', autoHideDuration: 8000 },
        );
        return undefined;
      }
    }

    const { mutation, variables } = resolveMutationParams(values, record, defaultDataPackageIdSet);
    const { errors } = await updateCompanySubscriptionSet({ mutation, variables });

    if (errors) {
      notify('Error updating company subscription set. Please try again.', { type: 'error' });
      return undefined;
    }

    // set new default values to updated values
    setDefaultValues(rawValues);
    window.scroll(0, 0);
    notify('The company subscription set has been successfully updated', { type: 'success' });
    refresh();

    return undefined;
  };

  const handleDelete = async () => {
    if (!record) return;

    try {
      await softDeleteCompanySubscriptionSet(
        'company_subscription_sets',
        { id: record.id, data: { deleted_at: new Date().toISOString() } },
        { returnPromise: true },
      );
      notify('The company subscription set has been successfully deleted', { type: 'success' });
    } catch (e) {
      notify('There was a problem deleting the company subscription set. Please try again.', { type: 'error' });
    } finally {
      redirect(externalApiClientEditRoute(record.external_api_client_id, ExternalApiClientEditTab.SUBSCRIPTIONS));
    }
  };

  if (currentSubscriptionCountsLoading || defaultDataPackageIdSetLoading) {
    return (
      <Box py={12} px={8}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <CraftTabbedForm
        formType="edit"
        defaultValues={defaultValues}
        onSubmit={handleSave}
        deleteOptions={{
          deletePermission: 'companySubscriptionSet:delete',
          dialogTitle: `Remove the "${record?.name}" company subscription set?`,
          onConfirmDelete: handleDelete,
          hideDeleteButton: record?.all_companies,
        }}
        disabled={!canEditCompanySubscriptionSet}
        shouldUnregister
        {...rest}
      >
        {children}
      </CraftTabbedForm>

      <LoadingOverlay open={updateCompanySubscriptionSetLoading || softDeleteCompanySubscriptionSetLoading} />
    </>
  );
};

export const CompanySubscriptionSetsEdit = () => {
  const notify = useNotify();

  const {
    data: defaultDataPackageRefs,
    error: defaultDataPackageRefsError,
    isLoading: defaultDataPackageRefsLoading,
  } = useGetList<DefaultDataPackageRecord>('default_data_packages', {
    sort: { field: 'data_package_id', order: 'ASC' },
    meta: { noLimit: true },
  });

  const defaultDataPackageIdSet =
    defaultDataPackageRefs && new Set<number>(defaultDataPackageRefs.map((ref) => ref.data_package_id));

  if (defaultDataPackageRefsError) {
    console.error(defaultDataPackageRefsError);
    notify('Error fetching data packages data', { type: 'error' });
  }

  return (
    <CraftEdit header="Edit Company Subscription Set" aside={<EditAside />} redirect={false}>
      <CompanySubscriptionSetContextProvider>
        <AddCompanySubscriptionsDialogContextProvider>
          <CustomTabbedForm
            defaultDataPackageIdSet={defaultDataPackageIdSet}
            defaultDataPackageIdSetLoading={defaultDataPackageRefsLoading}
          >
            <DetailsTab mode="edit" />
            <CompaniesTab />
            <DataPackagesTab defaultDataPackageIdSet={defaultDataPackageIdSet} />
          </CustomTabbedForm>

          <AddCompanySubscriptionsDialog />
        </AddCompanySubscriptionsDialogContextProvider>
      </CompanySubscriptionSetContextProvider>
    </CraftEdit>
  );
};

export default CompanySubscriptionSetsEdit;
