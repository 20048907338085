import { Typography } from '@mui/material';
import { AutocompleteArrayInput, FormDataConsumer, ReferenceArrayInput, useRecordContext } from 'react-admin';

import CraftPageSection from 'src/components/CraftPageSection';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import BooleanInput from 'src/inputs/BooleanInput';
import { CompanySubscriptionSetConfigRecord } from 'src/types';

const Permissions = () => {
  const companySubscriptionSetConfigRecord = useRecordContext<CompanySubscriptionSetConfigRecord>();

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <CraftPageSection title="Permissions">
          <Typography mb={1}>Add Company</Typography>
          <CraftTwoColumns
            left={
              <>
                <BooleanInput label="Permit all organization users" source="allowAllUsers" />
                <ReferenceArrayInput
                  reference="users"
                  source="add_company_allowed_user_id_whitelist"
                  filter={{
                    'roles#organization_id': companySubscriptionSetConfigRecord?.organization_id,
                    'deleted_at@_is_null': true,
                  }}
                  sort={{ field: 'email', order: 'ASC' }}
                  queryOptions={{ enabled: !!companySubscriptionSetConfigRecord?.organization_id }}
                >
                  <AutocompleteArrayInput
                    label="Permitted users"
                    disabled={formData.allowAllUsers}
                    optionText="email"
                    filterToQuery={(q) => ({ email: q })}
                  />
                </ReferenceArrayInput>
              </>
            }
            right={null}
          />
        </CraftPageSection>
      )}
    </FormDataConsumer>
  );
};

export default Permissions;
