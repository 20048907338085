import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import {
  Datagrid,
  EditButton,
  FunctionField,
  TextField,
  useCreate,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
  WrapperField,
} from 'react-admin';
import { Alert, Box, CircularProgress, Paper, Typography } from '@mui/material';

import client from 'src/data/clientApiClient';
import LoadingOverlay from 'src/components/LoadingOverlay';
import UnauthorizedSectionContent from 'src/components/UnauthorizedSectionContent';
import {
  CompanySubscriptionSetConfigRecord,
  CompanySubscriptionSetRecord,
  ExternalApiClientRecord,
  OrganizationRecord,
} from 'src/types';
import { CLIENT_API_API_KEY_HEADER_NAME, IS_LOCAL_OR_QA_ENV } from 'src/utils/defaults/Constants';
import { CraftPageSection } from 'src/components/CraftPageSection';
import { usePermissions } from 'src/hooks';
import { useState } from 'react';
import { getCompanySubscriptionSetConfigIdKey } from 'src/utils/idKey';

const clientCompanySubscriptionSetsGQL = gql`
  query Client {
    client {
      companySubscriptionSets {
        id
        name
      }
    }
  }
`;

const editButtonLabel = 'Edit Settings';

/**
 * @todo handle deleted configs
 */
const CompanySubscriptionSetSettingsList = () => {
  const organizationRecord = useRecordContext<OrganizationRecord>();
  const redirect = useRedirect();
  const notify = useNotify();

  const [isQaOrgWithoutStagingClient, setIsQaOrgWithoutStagingClient] = useState(false);

  // (QA env only) fetch subscription sets from Client API
  // TODO: Once Portal starts using QA Client API, use Admin API if this view isn't moved
  const {
    data: companySubscriptionSetsFromClientApiData,
    loading: companySubscriptionSetsFromClientApiLoading,
    error: companySubscriptionSetsFromClientApiError,
  } = useQuery<{ client: { companySubscriptionSets: { id: string; name: string }[] } }>(
    clientCompanySubscriptionSetsGQL,
    {
      client,
      // this query is only needed in the local and QA envs, to get subscription sets from Staging
      skip: !IS_LOCAL_OR_QA_ENV,
      context: {
        headers: { [CLIENT_API_API_KEY_HEADER_NAME]: organizationRecord.company_data_api_key },
      },
      // we don't want to cache this response, as the request headers are set for each org. that is
      // loaded, but Apollo Client doesn't consider the headers when resolving a cache key
      fetchPolicy: 'no-cache',
    },
  );

  if (companySubscriptionSetsFromClientApiError) {
    console.error(companySubscriptionSetsFromClientApiError);
    // If we get a 400 error from Client API, we'll assume that the key assigned to this org.
    // doesn't match an active API client (from Staging Client API).
    if (companySubscriptionSetsFromClientApiError.networkError?.message.includes('400')) {
      if (!isQaOrgWithoutStagingClient) setIsQaOrgWithoutStagingClient(true);
    } else {
      notify('Error fetching API client data', { type: 'error' });
    }
  }

  const {
    data: externalApiClientArr,
    isLoading: externalApiClientLoading,
    error: externalApiClientError,
  } = useGetList<ExternalApiClientRecord>(
    'external_api_clients',
    {
      filter: {
        'token@_eq': organizationRecord.company_data_api_key,
        'archived_at@_is_null': true,
      },
      pagination: { page: 1, perPage: 1 },
    },
    // we don't reference this in QA while QA orgs use Staging API clients
    { enabled: !IS_LOCAL_OR_QA_ENV },
  );
  // should only return 1 record
  const externalApiClient = externalApiClientArr?.[0];

  // fetch subscription sets from Craft Admin API
  const {
    data: companySubscriptionSetsFromAdminAPI,
    isLoading: companySubscriptionSetsFromAdminAPILoading,
    error: companySubscriptionSetsFromAdminAPIError,
  } = useGetList<CompanySubscriptionSetRecord>(
    'company_subscription_sets',
    {
      filter: {
        external_api_client_id: externalApiClient?.id,
        all_companies: false,
        'deleted_at@_is_null': true,
      },
      meta: { noLimit: true },
    },
    // we get this data from a different query in QA while QA orgs use Staging API clients
    { enabled: !IS_LOCAL_OR_QA_ENV && !!externalApiClient },
  );

  const {
    data: companySubscriptionSetConfigs,
    isLoading: companySubscriptionSetConfigsLoading,
    error: companySubscriptionSetConfigsError,
  } = useGetList<CompanySubscriptionSetConfigRecord>('company_subscription_set_configs', {
    filter: { organization_id: organizationRecord.id },
    meta: { noLimit: true },
  });

  if (externalApiClientError ?? companySubscriptionSetsFromAdminAPIError ?? companySubscriptionSetConfigsError) {
    console.error(
      externalApiClientError ?? companySubscriptionSetsFromAdminAPIError ?? companySubscriptionSetConfigsError,
    );
    notify('Error fetching company subscription set data', { type: 'error' });
  }

  const [createCompanySubscriptionSetConfig, { isLoading: createCompanySubscriptionSetConfigLoading }] = useCreate();

  const handleCreateCompanySubscriptionSetConfig = async (companySubscriptionSetID: string) => {
    try {
      await createCompanySubscriptionSetConfig(
        'company_subscription_set_configs',
        {
          data: {
            organization_id: organizationRecord.id,
            company_subscription_set_id: companySubscriptionSetID,
          },
        },
        { returnPromise: true },
      );
      redirect(
        'edit',
        'company_subscription_set_configs',
        getCompanySubscriptionSetConfigIdKey(organizationRecord.id, companySubscriptionSetID),
      );
    } catch (e) {
      console.error(e);
      notify('Error creating company subscription set config', { type: 'error' });
    }
  };

  /**
   * As QA orgs use Staging API clients, we have to fetch Company Subscription Sets from Staging
   * Client API for those orgs. If the current env is `local` or `qa`, then
   * `companySubscriptionSetsFromClientApiData` should be defined and used. Otherwise -
   * `companySubscriptionSetsFromAdminAPI` should be defined and used.
   * TODO: rm once QA Portal starts using QA Client API
   */
  const companySubscriptionSets =
    companySubscriptionSetsFromAdminAPI ??
    companySubscriptionSetsFromClientApiData?.client.companySubscriptionSets ??
    [];
  const companySubscriptionSetConfigsBySetIdMap: Partial<Record<string, CompanySubscriptionSetConfigRecord>> = {};
  companySubscriptionSetConfigs?.forEach((conf) => {
    companySubscriptionSetConfigsBySetIdMap[conf.company_subscription_set_id] = conf;
  });

  if (
    companySubscriptionSetsFromClientApiLoading ||
    externalApiClientLoading ||
    companySubscriptionSetsFromAdminAPILoading ||
    companySubscriptionSetConfigsLoading
  ) {
    return (
      <Box width="100%" textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {IS_LOCAL_OR_QA_ENV && (
        <Alert severity="info" sx={{ mb: 2 }}>
          ⚠️ QA ⚠️ - Subscription sets shown here are sourced from the{' '}
          <Typography fontWeight="700" component="span">
            Staging
          </Typography>{' '}
          API client used by this organization (if one exists).
        </Alert>
      )}

      <Datagrid
        data={companySubscriptionSets}
        sort={{ field: 'id', order: 'ASC' }}
        empty={
          <Paper>
            {isQaOrgWithoutStagingClient ? (
              <Alert severity="warning">
                We couldn&apos;t find an API client from the{' '}
                <Typography fontWeight="700" component="span">
                  Staging
                </Typography>{' '}
                environment that matches this organization&apos;s API key.
              </Alert>
            ) : (
              <Typography variant="body2" p={2}>
                No Company Subscription Sets found
              </Typography>
            )}
          </Paper>
        }
        size="medium"
      >
        <TextField label="ID" source="id" />
        <TextField source="name" />
        <WrapperField label="Actions">
          <FunctionField
            render={(companySubscriptionSet: { id: string }) => {
              const existingConfig = companySubscriptionSetConfigsBySetIdMap[companySubscriptionSet.id];
              return existingConfig ? (
                <EditButton
                  label={editButtonLabel}
                  resource="company_subscription_set_configs"
                  record={{ id: existingConfig.id }}
                />
              ) : (
                <EditButton
                  label={editButtonLabel}
                  to="#"
                  onClick={() => handleCreateCompanySubscriptionSetConfig(companySubscriptionSet.id)}
                />
              );
            }}
          />
        </WrapperField>
      </Datagrid>

      <LoadingOverlay open={createCompanySubscriptionSetConfigLoading} />
    </>
  );
};

const CompanySubscriptionSetSettings = () => {
  const { permissions } = usePermissions();

  return (
    <CraftPageSection title="Company Subscription Set Settings" mt={4}>
      {permissions.has('companySubscriptionSet:edit') ? (
        <CompanySubscriptionSetSettingsList />
      ) : (
        <UnauthorizedSectionContent />
      )}
    </CraftPageSection>
  );
};

export default CompanySubscriptionSetSettings;
