import {
  TextField,
  ExportButton,
  TopToolbar,
  EditButton,
  WrapperField,
  DateField,
  ReferenceOneField,
  BooleanField,
  SelectField,
  SearchInput,
  CreateButton,
  SortPayload,
} from 'react-admin';

import { usePermissions } from 'src/hooks';
import AuditBasedStatusFilterInput from 'src/inputs/AuditBasedStatusFilterInput';
import IntegerInput from 'src/inputs/IntegerInput';
import { ClearFiltersButton } from 'src/components/ClearFiltersButton';
import { CraftList } from 'src/components/CraftList';
import { AvatarField } from 'src/fields/AvatarField';
import { ORGANIZATION_TYPES } from 'src/utils/defaults/Constants';
import { CraftDateInput } from 'src/components/CraftDateInput';
import BulkSoftDeleteButton from 'src/components/BulkSoftDeleteButton';

export const PortalOrgsListFilters = [
  <SearchInput key="search" source="name,product_name" placeholder="Search" alwaysOn />,
  <IntegerInput key="searchByID" source="id" label="ID" alwaysOn />,
  <CraftDateInput key="createdAtFrom" label="Newer than" source="created_at@_gte" disallowFuture alwaysOn />,
  <CraftDateInput key="createdAtTo" label="Older than" source="created_at@_lte" alwaysOn />,
  <AuditBasedStatusFilterInput key="status" source="deleted_at@_is_null" deletedLabel="Deleted/Archived" alwaysOn />,
];

const defaultSort: SortPayload = {
  field: 'created_at',
  order: 'DESC',
};

const columnStyles = {
  id: {
    width: '1rem',
  },
  logo_url: {
    width: '3rem',
    textAlign: 'center',
    '& > *': {
      margin: '0 auto',
    },
  },
  created_at: {
    width: '10rem',
  },
  updated_at: {
    width: '10rem',
  },
  actions: {
    width: '8rem',
  },
};

const PortalOrgsActions = ({ canCreate }: { canCreate: boolean }) => (
  <TopToolbar>
    <ClearFiltersButton />
    <ExportButton />
    {canCreate && <CreateButton />}
  </TopToolbar>
);

export const PortalOrgsList = () => {
  const { permissions } = usePermissions();

  const canEdit = permissions.has('organization:edit');

  const bulkActionButtons = permissions.has('organization:delete') ? (
    <BulkSoftDeleteButton label="Delete Selected" />
  ) : (
    false
  );

  return (
    <CraftList
      title="Organizations"
      filters={PortalOrgsListFilters}
      actions={<PortalOrgsActions canCreate={canEdit} />}
      sort={defaultSort}
      columnStyles={columnStyles}
      filterDefaultValues={{ 'deleted_at@_is_null': true }}
      showClearButton={false}
      deletePermission="organization:delete"
      dataGridProps={{
        bulkActionButtons,
      }}
    >
      <TextField source="id" />
      <AvatarField source="logo_url" label="Logo" />
      <TextField source="name" />
      <TextField source="product_name" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <SelectField source="type" choices={ORGANIZATION_TYPES} />
      <BooleanField source="alerts" label="Show Alerts" />
      <ReferenceOneField sortable={false} label="Send Email Alerts" reference="organization_config" target="org_id">
        <BooleanField source="send_email_alerts" />
      </ReferenceOneField>
      {canEdit ? (
        <WrapperField label="Actions" headerClassName="column-actions" cellClassName="column-actions">
          <EditButton />
        </WrapperField>
      ) : null}
    </CraftList>
  );
};

export default PortalOrgsList;
