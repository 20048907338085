import { Paper, Typography } from '@mui/material';

export const UnauthorizedSectionContent = () => (
  <Paper>
    <Typography variant="body2" my={4} textAlign="center">
      Additional permissions required
    </Typography>
  </Paper>
);

export default UnauthorizedSectionContent;
