import { Identifier } from 'react-admin';
import { ID_KEY_DELIMITER } from './defaults/Constants';

/**
 * Builds a composite ID, using the provided values, for use as an "ID Key" in Craft Admin UI.
 */
function getIdKey(...fields: Identifier[]): string {
  return fields.join(ID_KEY_DELIMITER);
}

export function getCompanySubscriptionSetConfigIdKey(orgID: Identifier, companySubscriptionSetID: Identifier): string {
  return getIdKey(orgID, companySubscriptionSetID);
}

export function extractComponentsFromSubscriptionSetConfigIdKey(key: string): {
  organizationID: string;
  companySubscriptionSetID: string;
} {
  const [organizationID, companySubscriptionSetID] = key.split(ID_KEY_DELIMITER);
  return { organizationID, companySubscriptionSetID };
}
