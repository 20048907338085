/* eslint-disable no-param-reassign */

import gql from 'graphql-tag';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import { DocumentNode, IntrospectionType, SelectionNode, FieldNode, Kind } from 'graphql';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import { IntrospectionResult, introspectSchema, ALL_TYPES } from 'ra-data-graphql';
import {
  buildFields,
  buildApolloArgs,
  buildArgs,
  buildMetaArgs,
  buildGqlQuery,
  getResponseParser,
  buildVariables,
  FetchType,
} from 'ra-data-hasura';

import { getExtension, isExtendedResource, resourceMap } from '../resources';
import { HasuraBoolExpression, HasuraComparisonExpression } from 'src/utils/HasuraTypes';
import { buildQueryFactory } from './buildQueryFactory';
import { IdKeyDefinition } from 'src/types';
import { ID_KEY_DELIMITER } from 'src/utils/defaults/Constants';
import { QueryResponse } from 'ra-data-hasura/dist/buildQuery';
import { visitBoolExpression } from 'src/utils/hasura';

type OperationNameMapper = Record<FetchType, (type: IntrospectionType) => string>;
type ResponseField = Record<string, unknown>;
type ProviderResponse = {
  data: ResponseField;
};

const defaultOperationNames: OperationNameMapper = {
  [GET_LIST]: (resource) => `${resource.name}`,
  [GET_ONE]: (resource) => `${resource.name}`,
  [GET_MANY]: (resource) => `${resource.name}`,
  [GET_MANY_REFERENCE]: (resource) => `${resource.name}`,
  [CREATE]: (resource) => `insert_${resource.name}`,
  [UPDATE]: (resource) => `update_${resource.name}`,
  [UPDATE_MANY]: (resource) => `update_${resource.name}`,
  [DELETE]: (resource) => `delete_${resource.name}`,
  [DELETE_MANY]: (resource) => `delete_${resource.name}`,
};

/**
 * These operations hold a singular resource in the data provider response "data"
 */
export const SINGULAR_OPERATION_TYPES = new Set([GET_ONE, CREATE, UPDATE, DELETE]);

/**
 * These operations hold an array of resources in the data provider response "data"
 */
export const PLURAL_OPERATION_TYPES = new Set([GET_LIST, GET_MANY, GET_MANY_REFERENCE]);

/**
 * These operations hold an array of identifiers in the data provider response "data",
 * not the actual resources.
 */
export const PLURAL_IDENTIFIER_OPERATION_TYPES = new Set([UPDATE_MANY, DELETE_MANY]);

/**
 * This function allows you to fully control how ra-data-hasura builds and sends
 * queries to the Craft Admin API, you can extend it in three main places:
 *
 * - Build Variables
 *   - Override query variable values sent to the actual API
 * - Build Query
 *   - Override query contents, add/remove fields, add nested resources, etc.
 * - Get Response Parser
 *   - Modify response from the API before it's returned back to the application
 */
export const buildQuery = buildQueryFactory(
  (ctx) => {
    const defaultBuildVariables = buildVariables(ctx.introspectionResults);
    const extension = getExtension(ctx.resource?.type?.name);

    if (extension?.transformParams) {
      ctx = extension.transformParams(ctx);
    }

    const variables = defaultBuildVariables(ctx.resource, ctx.operation.type, ctx.operation.params, ctx.queryType);

    if (extension?.idKey) {
      if (extension.idKey && variables.where) {
        variables.where = resolveIdFilter(variables.where, extension.idKey);
      }

      if (variables.order_by?.id) {
        // In the case of a custom ID, replace "id" field in sort params
        const { id: orderValue, ...rest } = variables.order_by;
        variables.order_by = { ...rest };
        for (const key of extension.idKey) {
          variables.order_by[key] = orderValue;
        }
      }
    }

    // handle option to exclude limit on number of returned items
    if (ctx.operation.params?.meta?.noLimit && variables?.limit) {
      variables.limit = undefined;
      variables.offset = undefined;
    }

    return variables;
  },
  (ctx) => {
    const defaultBuildQuery = buildGqlQuery(
      ctx.introspectionResults,
      (...args) => {
        const [type] = args;
        const defaultFields = buildFields(...args);

        if (isExtendedResource(type.name)) {
          const extension = getExtension(type.name);

          // --- Write extension code here
          // Modify fields of resources before they're requested from the Craft Admin API
          // - append new fields, add aliases, nested resources, etc.

          if (extension?.idKey) {
            // Append "id" alias pointing to an actual identifier column
            defaultFields.push(...extractFieldsFromQuery(gql`{ id: ${extension.idKey} }`));
          }

          if (extension?.extraFields) {
            // Append extra fields to the query
            defaultFields.push(...extractFieldsFromQuery(extension.extraFields));
          }

          if (extension?.excludeFields) {
            const fieldsToExcludeAST =
              typeof extension.excludeFields === 'function'
                ? extension.excludeFields(ctx.permissions)
                : extension.excludeFields;
            if (fieldsToExcludeAST) {
              const fieldNodesToExclude = extractFieldsFromQuery(fieldsToExcludeAST);
              removeQueryFieldSelections(fieldNodesToExclude, defaultFields);
            }
          }
        }

        return defaultFields;
      },
      buildMetaArgs,
      buildArgs,
      buildApolloArgs,
      () => 'NO_COUNT',
    );

    const extension = getExtension(ctx.resource?.type?.name);

    if (extension?.transformVariables) {
      ctx = extension.transformVariables(ctx);
    }

    const defaultQuery = defaultBuildQuery(ctx.resource, ctx.operation.type, ctx.queryType, ctx.variables);

    // --- Write extension code here
    // Modify the full produced query AST.
    // - Swap the query with something else
    // - Make fields/arguments/variables disappear

    return defaultQuery;
  },
  (ctx) => {
    const defaultParser = getResponseParser(ctx.introspectionResults);

    return function responseParser(res) {
      let result = { ...res };
      const extension = getExtension(ctx.resource?.type.name);

      // --- Write extension code here
      // Modify the response content before it's returned to the application
      // - Add computed fields
      // - Normalise field types
      // - Flatten the data model

      if (extension?.idKey) {
        result = injectIdKey(result as ProviderResponse, extension.idKey) as typeof res;
      }

      let parsedResult = defaultParser(ctx.operation.type, ctx.resource)(result);

      // Add pagination information to the response
      if (ctx.operation.type === GET_MANY_REFERENCE || ctx.operation.type === GET_LIST) {
        const { params } = ctx.operation;

        // Override default ra-data-hasura "endless" pagination
        parsedResult.pageInfo = {
          hasPreviousPage: params.pagination.page > 1,
          hasNextPage: parsedResult?.data?.length === params.pagination.perPage,
        };
      }

      if (extension?.transformResponse) {
        parsedResult = extension.transformResponse(ctx, parsedResult);
      }

      if (extension?.transformResource) {
        // Separate variable, so that TS remembers it's non-nullable at this stage.
        const transformer = extension.transformResource;

        if (PLURAL_OPERATION_TYPES.has(ctx.operation.type)) {
          parsedResult.data = parsedResult.data.map((item: QueryResponse['data']) => transformer(ctx, item));
        }

        if (SINGULAR_OPERATION_TYPES.has(ctx.operation.type)) {
          parsedResult.data = transformer(ctx, parsedResult.data);
        }

        // For PLURAL_IDENTIFIER_OPERATION_TYPES, we don't have a resource instance
        // to transform at all.
      }

      return parsedResult;
    };
  },
);

/**
 * Allows to customize the introspection procedure that defines
 * which resources/operations are available for the User in the application.
 */
export const resolveIntrospection: typeof introspectSchema = async (apollo, opts) => {
  const result: IntrospectionResult = await introspectSchema(apollo, {
    ...opts,
    // Override operation names so that we account that
    // resource name and query field may differ from one another
    operationNames: (ALL_TYPES as FetchType[]).reduce((acc, fetchType) => {
      acc[fetchType] = (type: IntrospectionType) => resolveOperationName(fetchType, type);
      return acc;
    }, {} as OperationNameMapper),

    // Ensure that all the resources from our list
    // are included in the introspection. Otherwise,
    // if a field is nested/named differently, ra-data-hasura
    // may skip it altogether.
    include: Object.keys(resourceMap),
  });

  // --- Write extension code here
  // To modify the introspection object.
  // - Add "virtual" resources
  // - Choose a different return type for a resource

  return result;
};

/**
 * Resolves the actual query field for the given operation and resource.
 * @param fetchType - represents operation to be performed
 * @param type - represents the queried resource (as per introspection results)
 */
function resolveOperationName(fetchType: FetchType, type: IntrospectionType) {
  if (isExtendedResource(type.name)) {
    const extension = getExtension(type.name);

    if (extension) {
      const operationName = extension.operations?.[fetchType]?.operationName;

      if (operationName) {
        return operationName;
      }
    }
  }

  return defaultOperationNames[fetchType](type);
}

/**
 * Extracts just the fields from a GraphQL AST.
 */
function extractFieldsFromQuery(queryAst: DocumentNode) {
  // @ts-expect-error we need a lot of type routing to resolve this properly
  return queryAst.definitions[0].selectionSet.selections;
}

/**
 * Injects a custom id key to the given response from Apollo Client.
 * Ensures that original cached objects are not modified.
 */
function injectIdKey<T extends ProviderResponse>(res: T, idKey: IdKeyDefinition): T {
  const idSources = Array.isArray(idKey) ? idKey : [idKey];
  const addIdKey = <Input extends ResponseField>(obj: Input) => ({
    ...obj,
    id: idSources.map((path) => get(obj, path)).join(ID_KEY_DELIMITER),
  });
  const mapInput = <Input extends ResponseField>(obj: Input, field: string) => {
    if (Array.isArray(obj[field])) {
      const items = obj[field] as Array<Input>;

      return {
        ...obj,
        [field]: items.map((item) => addIdKey(item)),
      };
    }

    if (isObject(obj[field])) {
      return {
        ...obj,
        [field]: addIdKey(obj[field] as ResponseField),
      };
    }

    return obj;
  };

  if (res) {
    if (res.data.items) {
      res.data = mapInput(res.data, 'items');
    }

    if (res.data.returning) {
      res.data = mapInput(res.data, 'returning');
    }
  }

  return res;
}

/**
 * Given the input bool expression ("where" filter for Hasura),
 * rewrites it so that it accounts for custom id keys from the
 * resource definition.
 */
export function resolveIdFilter(where: HasuraBoolExpression, idKey: IdKeyDefinition) {
  const result = { ...where };

  visitBoolExpression(result, (expr) => {
    if (expr.id) {
      const idElements = Array.isArray(idKey) ? idKey : [idKey];

      for (const [operator, value] of Object.entries(expr.id)) {
        idElements.forEach((idElement, index) => {
          expr[idElement] = expr[idElement] || {};
          const comparison = expr[idElement] as HasuraComparisonExpression;

          if (Array.isArray(value)) {
            comparison[operator] = value.map((v) => (typeof v === 'string' ? v.split(ID_KEY_DELIMITER)[index] : v));
          } else if (typeof value === 'string') {
            comparison[operator] = value.split(ID_KEY_DELIMITER)[index];
          } else {
            comparison[operator] = value;
          }
        });
      }

      delete expr.id;
    }
  });

  return result;
}

const isFieldNode = (val: SelectionNode | undefined): val is FieldNode => val?.kind === Kind.FIELD;

/**
 * Takes a reference of field selections (`selectionsToRemoveReference`) and removes matching nodes
 * from the given target selections.
 */
function removeQueryFieldSelections(
  selectionsToRemoveReference: readonly SelectionNode[],
  targetSelections: SelectionNode[],
) {
  for (const nodeToExcludeRef of selectionsToRemoveReference) {
    if (!isFieldNode(nodeToExcludeRef)) continue;

    const targetNodeIdx = targetSelections.findIndex((node) => {
      if (!isFieldNode(node)) return false;
      return node.name.value === nodeToExcludeRef.name.value;
    });
    const targetNode = targetSelections[targetNodeIdx];
    if (!isFieldNode(targetNode)) continue;

    if (nodeToExcludeRef.selectionSet) {
      // sub-fields specified - check those
      if (!targetNode.selectionSet) continue;
      removeQueryFieldSelections(
        nodeToExcludeRef.selectionSet.selections,
        targetNode.selectionSet.selections as SelectionNode[],
      );
      continue;
    }

    // no sub-fields specified - exlude this field
    targetSelections.splice(targetNodeIdx, 1);
  }
}
