import { Loading, SimpleForm, SimpleFormProps, TransformData, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

import CraftEdit from 'src/components/CraftEdit';
import CraftTwoColumns from 'src/components/CraftTwoColumns';
import CraftPageSection from 'src/components/CraftPageSection';
import FormToolbar from 'src/components/FormToolbar';
import TextInput from 'src/inputs/TextInput';
import EditAside from './EditAside';
import PermissionsSection from './formSections/Permissions';
import { CompanySubscriptionSetConfigRecord } from 'src/types';
import { arrayToPostgresLiteral } from 'src/utils/hasura';

const CustomForm = ({ children, ...rest }: SimpleFormProps) => {
  const companySubscriptionSetConfigRecord = useRecordContext<CompanySubscriptionSetConfigRecord>();

  if (!companySubscriptionSetConfigRecord) {
    return (
      <Box py={12} px={8}>
        <Loading />
      </Box>
    );
  }

  const defaultValues = {
    allowAllUsers: !companySubscriptionSetConfigRecord.add_company_allowed_user_id_whitelist,
  };

  return (
    <SimpleForm
      toolbar={<FormToolbar deleteOptions={{ showDeleteButton: false }} />}
      defaultValues={defaultValues}
      {...rest}
    >
      {children}
    </SimpleForm>
  );
};

const transformValues: TransformData = (values) => {
  const result = {
    ...values,
    updated_at: new Date().toISOString(),
  };

  result.add_company_allowed_user_id_whitelist = values.allowAllUsers
    ? null
    : arrayToPostgresLiteral(values.add_company_allowed_user_id_whitelist);

  return result;
};

/**
 * @todo handle deleted configs
 */
const CompanySubscriptionSetConfigsEdit = () => (
  <CraftEdit
    header="Edit Company Subscription Set Config"
    aside={<EditAside />}
    transform={transformValues}
    redirect={false}
    mutationMode="optimistic"
  >
    <CustomForm>
      <CraftPageSection title="General">
        <CraftTwoColumns
          left={<TextInput source="organization_id" disabled />}
          right={<TextInput source="company_subscription_set_id" disabled />}
        />
      </CraftPageSection>

      <PermissionsSection />
    </CustomForm>
  </CraftEdit>
);

export default CompanySubscriptionSetConfigsEdit;
