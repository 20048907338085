import { useState } from 'react';
import {
  Button,
  ButtonProps,
  Identifier,
  useDataProvider,
  useNotify,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import {
  ExternalApiClientEditTab,
  OrganizationEditTab,
  externalApiClientEditRoute,
  organizationEditRoute,
} from 'src/utils/routeHelpers';
import { extractComponentsFromSubscriptionSetConfigIdKey } from 'src/utils/idKey';

export type BackButtonBehavior = 'back' | 'up' | 'root';

/**
 * Navigates the user "back", supports different behaviors as
 * per the {@link BackButtonBehavior} enum.
 *
 * - "back" - default, navigates to previous history state
 * - "up" - navigates the User one path section up: '/resource/:id/page' -> '/resource/:id'
 * - "root" - navigates to the root path section: '/resource/:id/page' -> '/resource'
 */
export const BackButton = ({ behavior = 'back', ...rest }: ButtonProps & { behavior?: BackButtonBehavior }) => {
  const [searchParams] = useSearchParams();

  const fromOrgId = searchParams.get('fromOrgId') || '';

  const navigate = useNavigate();
  const location = useLocation();
  let resource = useResourceContext();
  const record = useRecordContext(); // TODO: get consistent record context
  const dataProvider = useDataProvider();
  const notify = useNotify();

  // all standard resource routes EXCEPT "create" have signature /<resource>/<resourceID>...
  // "create" routes have signature /<resource>/create...
  const pathSections = location.pathname.split('/');
  // this component might not have access to resource or record contexts
  resource ??= pathSections[1];
  const isCreateRoute = pathSections[2] === 'create';
  const entityID: Identifier | undefined = isCreateRoute
    ? undefined
    : record?.id ?? decodeURIComponent(pathSections[2]);

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    const navigateToRoot = () => {
      const [, root] = pathSections;
      navigate({
        pathname: root,
      });
    };

    const navigateUp = () => {
      navigate({
        pathname: pathSections.slice(0, -1).join('/'),
      });
    };

    if (fromOrgId) {
      navigate(organizationEditRoute(fromOrgId, OrganizationEditTab.SUBSCRIPTION));
      return;
    }

    if (resource === 'company_subscription_sets') {
      setLoading(true);
      let externalApiClientId = isCreateRoute
        ? searchParams.get('externalApiClientID')
        : record?.external_api_client_id;
      if (!externalApiClientId) {
        try {
          const fetchedRecord = await dataProvider.getOne(resource, {
            id: entityID,
          });
          externalApiClientId = fetchedRecord.data.external_api_client_id;
        } catch (e) {
          notify('There was a problem redirecting to the API client');
          navigate('/external_api_clients');
        }
      }
      navigate(externalApiClientEditRoute(externalApiClientId, ExternalApiClientEditTab.SUBSCRIPTIONS));
      setLoading(false);
      return;
    }

    if (resource === 'company_subscription_set_configs' && entityID) {
      const { organizationID } = extractComponentsFromSubscriptionSetConfigIdKey(String(entityID));
      navigate(organizationEditRoute(organizationID, OrganizationEditTab.SUBSCRIPTION));
      return;
    }

    switch (behavior) {
      case 'root':
        navigateToRoot();
        break;
      case 'up':
        navigateUp();
        break;
      default:
        navigate(-1);
        break;
    }
  };

  if (loading) Object.assign(rest, { disabled: true, label: 'Loading...', startIcon: undefined });

  return <Button onClick={handleClick} {...rest} />;
};

export default BackButton;
