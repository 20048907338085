import { GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE } from 'ra-core';

import CompanySubscriptionSetConfigsEdit from './Edit';
import { ResourceDefinition, ResourceSections } from 'src/types';

export const CompanySubscriptionSetConfigsResource: ResourceDefinition = {
  name: 'company_subscription_set_configs',
  section: ResourceSections.CLIENT_API,

  edit: CompanySubscriptionSetConfigsEdit,

  permissions: {
    edit: ['organization:edit', 'companySubscriptionSet:edit'],
  },

  extension: {
    idKey: ['organization_id', 'company_subscription_set_id'],
    operations: {
      [GET_LIST]: {
        operationName: 'portal_company_subscription_set_configs',
      },
      [GET_ONE]: {
        operationName: 'portal_company_subscription_set_configs',
      },
      [GET_MANY]: {
        operationName: 'portal_company_subscription_set_configs',
      },
      [GET_MANY_REFERENCE]: {
        operationName: 'portal_company_subscription_set_configs',
      },
    },
  },
};
